export type IPrcUserTypes = 'Administrator' | 'User' | 'Read';

export enum PrcUserTypesEnum {
  Administrator = 'Administrator',
  User = 'User',
  Read = 'Read'
}

export type INpsUserTypes =
  | 'NPSRegionPA'
  | 'NPSLegalApprover'
  | 'NPSPriceCoordinator'
  | 'NpsAdministrator'
  | 'NPSReadOnly';

export enum NpsUserTypesEnum {
  PricingManager = 'NPSRegionPA',
  Legal = 'NPSLegalApprover',
  PriceCoordinator = 'NPSPriceCoordinator',
  NpsAdministrator = 'NpsAdministrator',
  NpsReadOnly = 'NPSReadOnly'
}

export type IAllUserTypes = IPrcUserTypes | INpsUserTypes;
